.timer {
    text-align: center;
    padding: 40px 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background: linear-gradient(to right, #ff6b6b, #f06595, #cf52a1, #8b3eac, #5639ad); /* Gradient background */
    color: white;
}

.timer h2 {
    font-size: 3em;
    margin-bottom: 10px;
}

.timer p {
    font-size: 1.2em;
    margin-bottom: 20px;
}

.countdown {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.time-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 10px;
    border-radius: 5px;
    min-width: 60px;
}

.time {
    font-size: 2.5em;
    color: #333;
}

.label {
    font-size: 1em;
    color: #777;
}
