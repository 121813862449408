.app {
  font-family: 'Roboto', sans-serif; 
  color: #333;
  line-height: 1.6;
  background-color: #f0f0f0;
}

.ceremonies {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px; /* Space between cards */
}
