.header {
    position: relative;
    height: 100vh;
    width: 100%;
    background-image: url('https://ucarecdn.com/75c61436-ca00-4e81-91a1-9867b71be4cd/-/preview/766x1000');
    background-size: cover;
    background-position: center;
    background-attachment: fixed; /* Parallax effect */
    display: flex;
    justify-content: center;
    align-items: center;
}

.overlay {
    position: absolute;
    left: 0;
    bottom: 0px;
    width: 100vw;
    height: auto;
    background-color: rgba(0, 0, 0, 0.5); /* Dark overlay for better text readability */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
}

.overlay h1 {
    font-size: 3em;
    margin: 0;
}

.overlay p {
    font-size: 1.5em;
    margin: 10px 0 0;
}

/* Adjust font sizes for smaller devices */
@media (max-width: 768px) {
    .overlay {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .overlay h1 {
        font-size: 2em;
    }

    .overlay p {
        font-size: 1.2em;
        margin: 5px 0 0;
    }
}
