.faq-container {
    max-width: 100%;
    margin: 0 auto;
    padding: 20px;
    text-align: left;
    background-color: white;
}

.faq-container h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2em;
    color: #333;
}

.accordion {
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
}

.accordion-item {
    border-bottom: 1px solid #ccc;
}

.accordion-item:last-child {
    border-bottom: none;
}

.accordion-question {
    padding: 15px;
    background-color: #f9f9f9;
    cursor: pointer;
    font-size: 1.2em;
    color: #333;
    transition: background-color 0.3s;
}

.accordion-question:hover {
    background-color: #f1f1f1;
}

.accordion-answer {
    max-height: 0;
    overflow: hidden;
    padding: 0 15px;
    background-color: #fff;
    font-size: 1em;
    color: #666;
    transition: max-height 0.5s ease, padding 0.5s ease;
}

.accordion-answer p,
.accordion-answer ul {
    margin: 10px 0;
}

.accordion-answer ul {
    padding-left: 20px;
}

.accordion-answer.active {
    max-height: 1000px;
    padding: 15px;
    transition: max-height 0.5s ease, padding 0.5s ease;
}
