.rsvp-container {
    text-align: center;
    margin: 20px 0;
}

.heartfelt-message {
    margin-bottom: 20px;
    padding: 20px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the content horizontally */
    justify-content: center; /* Center the content vertically */
    text-align: center;
    height: 35vh; 
    box-sizing: border-box;
}
@media screen and (max-width: 768px) {
   .heartfelt-message {
        height: 75%;
    }
    
}
.heartfelt-message h2 {
    margin-bottom: 10px;
    font-size: 1.5em;
    color: #333;
}

.heartfelt-message p {
    font-size: 1em;
    color: #666;
    margin-bottom: 20px;
}

.rsvp-button {
    padding: 10px 20px;
    background-color: #ff6b6b;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    text-decoration: none;
    display: block;
    margin: 0 auto;
}

.rsvp-button:hover {
    background-color: #e05555;
}

.google-signin-button {
    padding: 10px 20px;
    background-color: #4285F4;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    text-decoration: none;
    display: block;
    margin: 0 auto;
}

.google-signin-button:hover {
    background-color: #357ae8;
}

.rsvp-form {
    text-align: left;
}

.rsvp-form p {
    margin-bottom: 20px;
    font-size: 1em;
    color: #333;
}

.info-inputs {
    display: flex;
    flex-direction: column;
}

.date-picker,
.phone-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.extra-details-input {
    width: 100%;
    height: 120px;
    padding: 12px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1em;
    resize: none;
    background-color: #f9f9f9;
}

.extra-details-input:focus {
    border-color: #ff6b6b;
    outline: none;
    background-color: #fff;
}

.submit-rsvp-button {
    padding: 10px 20px;
    background-color: #ff6b6b;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    text-decoration: none;
}

.submit-rsvp-button:hover {
    background-color: #e05555;
}

.error {
    color: red;
    font-size: 0.9em;
    margin-top: -8px;
    margin-bottom: 10px;
}

.success-message,
.error-message {
    text-align: center;
    font-size: 1em;
    margin-top: 20px;
    padding: 10px;
    border-radius: 5px;
}

.success-message {
    color: green;
    background-color: #e0f7e0;
}

.error-message {
    color: red;
    background-color: #f7e0e0;
}

.day-highlight {
    position: relative;
}

.day-highlight[title] {
    cursor: pointer;
}

.day-highlight[title]::after {
    content: attr(title);
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 2px 6px;
    border-radius: 4px;
    font-size: 0.75em;
    opacity: 0;
    transition: opacity 0.2s;
    pointer-events: none;
}

.day-highlight[title]:hover::after {
    opacity: 1;
}

.react-datepicker__day>.highlight-haldi {
    background-color: #ffeb3b;
    color: black;
}

.react-datepicker__day>.highlight-shadi {
    background-color: #ff9800;
    color: white;
}

.react-datepicker__day>.highlight-reception {
    background-color: #8bc34a;
    color: white;
}
