.ceremony-card {
    text-align: center;
    margin: 20px;
    width: 300px;
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

.ceremony-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
}

.ceremony-card h3 {
    font-size: 1.2em;
    margin: 10px 0;
}

.ceremony-card p {
    padding: 0 10px 10px;
    font-size: 0.9em;
    color: #555;
}
@media screen and (max-width: 768px) {
   .ceremony-card {
        width:85vw;
    }
    
}