.contact {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 40px 20px;
    background-color: #fff;
    border-top: 1px solid #ddd;
    margin-top: 20px;
}

.contact-info {
    flex: 1;
    padding-right: 20px;
}

.contact-info h2 {
    font-size: 2em;
    margin-bottom: 20px;
}

.contact-item h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
}

.contact-item p {
    font-size: 1em;
    margin-bottom: 10px;
}

.whatsapp-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #25D366;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    margin-top: 10px;
    text-decoration: none;
}

.whatsapp-button:hover {
    background-color: #1DA851;
}

.map-container {
    position: relative;
    flex: 1;
    padding-left: 20px;
}

.map-container iframe {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 10px;
}

.directions-button {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 10px 20px;
    background-color: #ff6b6b;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    text-decoration: none;
}

.directions-button:hover {
    background-color: #e05555;
}

@media (max-width: 768px) {
    .contact {
        flex-direction: column;
    }

    .contact-info, .map-container {
        padding: 0;
        margin: 10px 0;
    }
}
